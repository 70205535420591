<div class="container" *ngIf="logForm">
  <form [formGroup]="logForm">
    <div class="row">
      <div class="col">
        <mat-card>
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-title>Edit Event Log</mat-card-title>
              <mat-card-subtitle class="entryDateView" *ngIf="maintenanceLog">
                {{maintenanceLog.entryDate | date: 'MMM d, y'}}
              </mat-card-subtitle>
            </mat-card-title-group>
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col-3">
                <h2>business unit </h2>
                <mat-form-field appearance="outline">
                  <mat-label>select business unit</mat-label>
                  <mat-select name="BU" [(ngModel)]="selectedBU" (selectionChange)="updateBusinessUnit()"
                    formControlName="businessUnit" required>
                    <mat-option *ngFor="let businessUnit of businessUnits" [value]="businessUnit">
                      {{ businessUnit.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="hasError('businessUnit', 'required')">
                    Please select a business unit.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-3">
                <h2>facility</h2>
                <mat-form-field appearance="outline">
                  <mat-label>select facility</mat-label>
                  <mat-select name="Facility" [(ngModel)]="selectedFacility" (selectionChange)="updateEquipment()"
                    formControlName="facility" required>
                    <mat-option *ngFor="let facility of facilities" [value]="facility">
                      {{ facility.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="hasError('facility', 'required')">
                    Please select a facility.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-3">
                <h2>equipment</h2>
                <mat-form-field appearance="outline">
                  <mat-label>select compressor</mat-label>
                  <mat-select name="Compressor" [(ngModel)]="selectedCompressor" (selectionChange)="selectChange()"
                    formControlName="compressor" required>
                    <mat-option *ngFor="let compressor of compressors" [value]="compressor">
                      {{ compressor.compressorEquipmentId }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="hasError('compressor', 'required')">
                    Please select a compressor.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-3">
                <h2>seal placement</h2>
                <mat-form-field appearance="outline">
                  <mat-label>seal placement</mat-label>
                  <mat-select [(value)]="selectedsealplacement" [(ngModel)]="selectedsealplacement"
                    formControlName="sealPlacement">
                    <mat-option value="DE">DE</mat-option>
                    <mat-option value="NDE">NDE</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <!-- 2nd row -->
            <!-- <div class="row"> -->
            <!-- <div class="col-4"> -->

            <!-- <p>Current Date : {{todaydate}}</p> -->
            <!-- <h2>Current Date</h2> {{ today | date : "fullDate" }}
              
            </div>s
           -->
            <!-- <div class="col-4">
              <mat-form-field appearance="outline">
                <mat-label> Date In</mat-label>
              </mat-form-field>
            </div>
          
            <div class="col-4">
              <mat-form-field appearance="outline">
                <mat-label> Date Out</mat-label>
              </mat-form-field>
            </div>

          </div> -->
            <!-- 3rd row -->
            <div class="row">
              <div class="col-3">
                <h2>work order</h2>
                <mat-form-field appearance="outline">
                  <mat-label>enter work order</mat-label>
                  <input matInput [(ngModel)]="workorder" formControlName="workOrderNumber" />
                </mat-form-field>
              </div>

              <div class="col-3">
                <h2>reason</h2>
                <mat-form-field appearance="outline">
                  <mat-label>select reason </mat-label>
                  <mat-select [(value)]="selectedreasonforfailure" [(ngModel)]="selectedreasonforfailure"
                    (selectionChange)="enableFailureReasons()" formControlName="failureReason">

                    <mat-option value="PM">PM</mat-option>
                    <mat-option value="Failure">Failure</mat-option>
                    <mat-option value="Pending Failure">Pending Failure</mat-option>
                    <mat-option value="Paired Replacement">Paired Replacement</mat-option>


                  </mat-select>
                </mat-form-field>

              </div>

              <div class="col-3">
                <h2>failure type</h2>
                <mat-form-field appearance="outline">
                  <mat-label>select failure type</mat-label>
                  <mat-select [(value)]="selectedfailuretype" [(ngModel)]="selectedfailuretype"
                    formControlName="failureCause" [disabled]="isDisabled">

                    <mat-option value="Seal Contamination">Seal Contamination</mat-option>
                    <mat-option value="Manufacturer Defect">Manufacturer Defect</mat-option>
                    <mat-option value="Misalignment or Assembly">Misalignment or Assembly</mat-option>
                    <mat-option value="Compressor Issue">Compressor Issue</mat-option>
                    <mat-option value="Operator Issue">Operator Issue</mat-option>
                    <mat-option value="Not Yet Categorized">Not Yet Categorized</mat-option>
                  </mat-select>
                </mat-form-field>


              </div>

              <div class="col-3">
                <h2>seal failure</h2>
                <mat-form-field appearance="outline">
                  <mat-label>select seal failure</mat-label>
                  <mat-select [(value)]="selectedsealfailure" [(ngModel)]="selectedsealfailure"
                    formControlName="primarySecondarySeal" [disabled]="isDisabled">

                    <mat-option value="Primary">Primary</mat-option>
                    <mat-option value="Secondary">Secondary</mat-option>
                    <mat-option value="Both">Both</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>

            <div class="row">

              <div class="col-3">
                <h2>serial installed</h2>
                <mat-form-field appearance="outline">
                  <mat-label> enter serial number installed</mat-label>
                  <input matInput [(ngModel)]="serialNumberInstalled" formControlName="serialInstalled" />

                </mat-form-field>
              </div>
              <div class="col-3">
                <h2>serial removed</h2>
                <mat-form-field appearance="outline">
                  <mat-label> enter serial number removed</mat-label>

                  <input matInput [(ngModel)]="serialNumberRemoved" formControlName="serialRemoved" />
                </mat-form-field>
              </div>
              <div class="col-3">
                <h2>event date</h2>
                <mat-form-field appearance="outline">
                  <mat-label>mm/dd/yyyy</mat-label>
                  <input matInput [matDatepicker]="pickerIn" formControlName="dateIn" [(ngModel)]="dateIn">
                  <mat-datepicker-toggle matIconSuffix [for]="pickerIn"></mat-datepicker-toggle>
                  <mat-datepicker #pickerIn></mat-datepicker>
                </mat-form-field>
              </div>
              <!-- <div class="col-3">
                <h2>date out</h2>
                <mat-form-field appearance="outline">
                  <mat-label>mm/dd/yyyy</mat-label>
                  <input matInput [matDatepicker]="pickerOut" formControlName ="dateOut" [(ngModel)]="dateOut">
                  <mat-datepicker-toggle matIconSuffix [for]="pickerOut"></mat-datepicker-toggle>
                  <mat-datepicker #pickerOut></mat-datepicker>
                </mat-form-field>
              </div> -->

              <div class="row">
                <div class="col-3">
                  <h2>notes</h2>
                  <mat-form-field appearance="fill" class="fill-container">
                    <mat-label> notes</mat-label>
                    <textarea matInput [(ngModel)]="comments" formControlName="comments"></textarea>
                  </mat-form-field>
                </div>
                <div class="col-9 ">
                  <h2>links</h2>
                  <div class="table-container" formArrayName="dgsLinks">
                    <!-- <mat-card> -->
                    <button style="margin:5px;padding:10px;" mat-button color="primary"
                      (click)="addNewLink()">
                      <!-- <mat-icon>add</mat-icon> -->
                      Add New Link
                    </button>
                    <mat-table [dataSource]="dataSource" style="table-layout: fixed;">
                      <ng-container matColumnDef="linkId">
                        <mat-header-cell *matHeaderCellDef style="width: 10px !important">ID</mat-header-cell>
                        <mat-cell *matCellDef="let element;let i=index" [formGroupName]="i" style="width: 10px !important;">
                          {{element.get('linkId')?.value}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="linkUrl">
                        <mat-header-cell *matHeaderCellDef style="width: auto !important">Url</mat-header-cell>
                        <mat-cell *matCellDef="let element;let i=index" [formGroupName]="i" style="width: auto !important;">
                          <span
                            *ngIf="!element.get('isEditable')?.value && !element.get('isNewRow')?.value">{{element.get('linkUrl')?.value}}</span>
                          <input *ngIf="element.get('isEditable')?.value || element.get('isNewRow')?.value" matInput
                            formControlName="linkUrl"></mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="linkDescription">
                        <mat-header-cell *matHeaderCellDef style="width: auto;">Description</mat-header-cell>
                        <mat-cell *matCellDef="let element;let i=index" [formGroupName]="i" style="width: auto;">
                          <span
                            *ngIf="!element.get('isEditable')?.value && !element.get('isNewRow')?.value">{{element.get('linkDescription')?.value}}</span>
                          <input *ngIf="element.get('isEditable')?.value || element.get('isNewRow')?.value" matInput
                            formControlName="linkDescription"></mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="eventLogId">
                        <mat-header-cell *matHeaderCellDef style="width: auto;">Event ID</mat-header-cell>
                        <mat-cell *matCellDef="let element;let i=index" [formGroupName]="i" style="width: auto;">
                          <span>{{element.get('eventLogId')?.value}}</span>
                          <!-- <input matInput  formControlName="eventLogId" readonly>-->
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="sealSerial">
                        <mat-header-cell *matHeaderCellDef style="width: auto;">Seal Serial</mat-header-cell>
                        <mat-cell *matCellDef="let element;let i=index" [formGroupName]="i" style="width: auto;">
                          <span *ngIf="!element.get('isEditable')?.value">{{element.get('sealSerial')?.value}}</span>
                          <input *ngIf="element.get('isEditable')?.value || element.get('isNewRow')?.value" matInput
                            formControlName="sealSerial">
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef style="width: auto;"> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element;let i=index" [formGroupName]="i">
                          <button *ngIf="!element.get('isNewRow')?.value && !element.get('isEditable')?.value"
                            mat-icon-button matTooltip="Edit Link" (click)="editLink(element)">
                            <mat-icon>edit</mat-icon>
                          </button>
                          <button *ngIf="element.get('isEditable')?.value ||element.get('isNewRow')?.value "
                            mat-icon-button matTooltip="Save Link"
                            (click)="element.get('isNewRow')?.value ? saveNewLink(element): saveLink(element)">
                            <mat-icon>save</mat-icon>
                          </button>
                          <button *ngIf="element.get('isEditable')?.value ||element.get('isNewRow')?.value"
                            mat-icon-button matTooltip="Cancel Edit" (click)="cancelEdit(element)">
                            <mat-icon>cancel</mat-icon>
                          </button>
                          <button mat-icon-button matTooltip="Delete Link" color="warn"
                            (click)="deleteLink(element.get('linkId')?.value)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <!-- <mat-paginator #logPaginator [pageSizeOptions]="[2,5, 20]" showFirstLastButtons
                      aria-label="Select page of results">
                    </mat-paginator> -->
                    <!-- </mat-card> -->
                  </div>
                </div>
              </div>

              <div class="row" style="padding: 10px;"></div>
              <div class="row">
                <div class="col">

                  <div class="complete-button" mat-dialog-actions align="center">
                    <button mat-flat-button color="primary" (click)="saveeventlog()">
                      update event log
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h4>* required fields</h4>
                  </div>
                </div>

              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>