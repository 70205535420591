import { GetOrganizations } from '#models/GetOrganizations';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { SectionsService } from '#services/http/sections.service';
import { NotificationService } from '#services/notification.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/core/authentication/auth.service';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrl: './sections.component.scss'
})

export class SectionsComponent {
  isHeaderRow = false;
  sections: GetOrganizations[] = [];
  insertSection = new spGetOrganizations();
  updateSection = new spGetOrganizations();
  editable: boolean = false;
  isUpdateSection = false;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private notifyService: NotificationService,
    private sectionService: SectionsService, private authService: AuthService) {
  }

  ngOnInit() {
    this.sectionService.getSections().subscribe((res) => {
      this.sections = res;
    });
  }

  EditOrganization(orgCode: spGetOrganizations, index: any) {
    this.updateSection = orgCode;
    this.sections.splice(index, 1);
    this.isUpdateSection = true;
  }

  deleteOrganization(org: spGetOrganizations) {
    // this.sectionService.deleteOrganization(org, this.userId).subscribe(res => {
    //   window.location.reload();
    // });
  }
}
