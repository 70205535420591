import { Component } from '@angular/core';

@Component({
  selector: 'app-unit-types',
  templateUrl: './unit-types.component.html',
  styleUrl: './unit-types.component.scss'
})
export class UnitTypesComponent {

}
