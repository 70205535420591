import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'app/core/authentication/auth.service';
// Models
import { BusinessUnit } from '#models/business-unit';
import { Compressor } from '#models/compressor';
import { Facility } from '#models/facility';
import { MaintenanceLog } from '#models/maintenance-log';

// Services 
import { BusinessUnitService } from '#services/http/business-unit.service';
import { MaintenanceLogService } from '#services/http/maintenance-log.service';
import { CompressorService } from '#services/http/compressor.service';
import { DGSLinks } from '#models/dgs-links';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-edit-event-log',
  templateUrl: './edit-event-log.component.html',
  styleUrls: ['./edit-event-log.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditEventLogComponent implements OnInit {

  displayedColumns: string[] = ['linkId', 'linkUrl', 'linkDescription', 'eventLogId', 'sealSerial', 'action'];
  //displayedColumns: string[] = ['linkId', 'linkUrl', 'linkDescription'];

  // dataSource: MatTableDataSource<DGSLinks>;
  dataSource = new MatTableDataSource<any>();

  public businessUnits: BusinessUnit[];
  public selectedBU: BusinessUnit;
  public parameterBU: String;
  public facilities: Facility[];
  public selectedFacility: Facility = new Facility();
  public parameterFacility: String;
  public compressors: Compressor[];
  public selectedCompressor: Compressor;
  public parameterCompressorId: String = '';
  public maintenanceLog: MaintenanceLog;
  public parameterLogId: number;
  public workorder: string;
  public serialNumberInstalled: string;
  public serialNumberRemoved: string;
  public today: number = Date.now();
  public entryDate: Date;
  public dateIn: Date;
  public dateOut: Date;
  public currentdate: string;
  public comments: string;
  public editedLog: MaintenanceLog = new MaintenanceLog();
  public dgslinks: any[] = [];

  linkStr: string = '';
  description: string = '';
  linkDescription: string = '';
  selectedLink: string = '';
  selectedLinkUpdate: string = '';
  errorMessage: string = '';
  srNoErrorMessage: string = '';
  isEdit: boolean = false;
  updateLink = new DGSLinks();
  currentUser: string = ''
  public sealplacement: string;
  public selectedValue: any;
  public selectedfailurecause: any;

  logForm: FormGroup = this.fb.group({
    businessUnit: new FormControl('', Validators.required),
    entryDate: new FormControl({ value: formatDate(this.today, "dd-MM-yyyy", "en"), disabled: true }),
    dateIn: new FormControl({ value: formatDate(this.today, "dd-MM-yyyy", "en"), disabled: false }),
    facility: new FormControl({ value: '', disabled: true }),
    compressor: new FormControl({ value: '', disabled: true }),
    serialInstalled: new FormControl({ value: '', disabled: false }),
    serialRemoved: new FormControl({ value: '', disabled: false }),
    workOrderNumber: new FormControl({ value: 0, disabled: false }),
    comments: new FormControl({ value: '', disabled: false }),
    failureReason: new FormControl({ value: '', disabled: false }),
    primarySecondarySeal: new FormControl({ value: '', disabled: true }),
    failureCause: new FormControl({ value: '', disabled: true }),
    sealPlacement: new FormControl({ value: '', disabled: false }),
    dgsLinks: this.fb.array([])
  });
  selectedfailuretype = '';
  selectedreasonforfailure = '';
  selectedsealfailure = '';
  selectedsealplacement = '';
  public isDisabled: boolean = true;

  errorSnackBarConfig: MatSnackBarConfig = {
    duration: 5000,
    verticalPosition: 'bottom',
    panelClass: ['snackbar', 'snackbar-error'],
  }

  successSnackBarConfig: MatSnackBarConfig = {
    duration: 5000,
    verticalPosition: 'bottom',
    panelClass: ['snackbar', 'snackbar-success'],
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<MaintenanceLog>;


  isLoading = true;

  constructor(
    private activeRoute: ActivatedRoute,
    private businessUnitSvc: BusinessUnitService,
    private compressorSvc: CompressorService,
    private maintananceLogSvc: MaintenanceLogService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    // do nothing
  }

  ngOnInit() {

    const userInfo = this.authService.getCurrentUser();
    this.currentUser = userInfo.username;

    this.activeRoute.queryParams.subscribe((params) => {
      this.parameterLogId = params['maintenanceLogId'];
      this.parameterBU = params['businessUnit'];
      this.parameterFacility = params['facility'];
      this.parameterCompressorId = params['compressorId']
    });

    // getting stored log specific information
    this.getEventLog(this.parameterLogId);

    // setting BU, Facility and Compressor Information and options 
    this.getBusinessUnits();

    //this.updateFailureReasons();
    this.getDGSLinks(this.parameterLogId);

    this.isLoading = false;
  }
  get dgsLinksArray(): FormArray {
    return this.logForm.get('dgsLinks') as FormArray;
  }

  getDGSLinks(eventId: number): void {
    this.maintananceLogSvc.getDGSLinks(eventId).subscribe((data: any[]) => {
      this.dgslinks = data;
      console.log(data)
      this.dgsLinksArray.clear()

      data.forEach(link => {
        const linkGroup = this.fb.group({
          linkId: new FormControl(link.linkId),
          linkUrl: new FormControl(link.linkUrl),
          linkDescription: new FormControl(link.linkDescription),
          eventLogId: new FormControl(link.eventLogId),
          sealSerial: new FormControl(link.sealSerial),
          createdBy: new FormControl(link.createdBy),
          createdOn: new FormControl(link.createdOn),
          isEditable: new FormControl(false),
        })
        this.dgsLinksArray.push(linkGroup)
      })

      this.updateDataSource();
    })
  }
  updateDataSource(): void {
    this.dataSource = new MatTableDataSource(this.dgsLinksArray.controls);
  }

  addNewLink(): void {

    const newLink = this.fb.group({
      linkId: new FormControl(0),
      linkUrl: new FormControl('', [Validators.required]),
      linkDescription: new FormControl(''),
      eventLogId: new FormControl(this.parameterLogId),
      sealSerial: new FormControl(''),
      isEditable: new FormControl(true),
      isNewRow: new FormControl(true)
    })

    this.dgsLinksArray.insert(0, newLink);

    this.updateDataSource();
  }

  saveNewLink(element: any): void {

    if (element.value.linkUrl == null || element.value.linkUrl == '') {
      this.snackBar.open('Please provide a valid link url before saving.', 'Close', { duration: 2000 });
      return;
    }

    const newLinkValues = { ...element.value }
    delete newLinkValues.isEditable
    delete newLinkValues.isNewRow

    const payload = [
      {
        linkId: 0,
        eventLogId: newLinkValues.eventLogId,
        linkUrl: newLinkValues.linkUrl,
        linkDescription: newLinkValues.linkDescription,
        createdBy: this.currentUser,
        sealSerial: newLinkValues.sealSerial,
      },
    ];

    this.maintananceLogSvc.postDGSLink(payload).subscribe({
      next: (createdLink) => {
        console.log(createdLink)
        element.patchValue({
          linkId: createdLink[0].linkId,
          isEditable: false,
          isNewRow: false
        });

        this.snackBar.open('Link added successfully', 'Close', { duration: 2000 });
      },
      error: (err) => {
        console.error('Failed to add new link', err)
        this.snackBar.open('Failed to add new link', 'Close', { duration: 2000 });
      }
    });
  }
  editLink(element: any): void {
    this.resetAllEditableFlags()

    const rowToEdit = this.dgsLinksArray.controls.find(
      (control) => control.get('linkId')?.value === element.value.linkId
    );
    console.log(rowToEdit)
    if (rowToEdit) {
      rowToEdit.patchValue({ isEditable: true });
      element.previousValue = { ...element };
    }
  }
  saveLink(element: any): void {
    if (element.value.linkUrl == null || element.value.linkUrl == '') {
      this.snackBar.open('Please provide a valid link url before saving.', 'Close', { duration: 2000 });
      return;
    }
    this.resetAllEditableFlags();
    const linkValues = element.value
    this.maintananceLogSvc.putDGSLink(element.value.linkId, linkValues).subscribe({
      next: () => {
        this.snackBar.open('Link updated successfully', 'Close', { duration: 2000 });
      },
      error: (err) => {
        console.error('Update failed', err)
        this.snackBar.open('Failed to update link', 'Close', { duration: 2000 });
      }
    });
  }

  resetAllEditableFlags() {
    this.dgsLinksArray.controls.forEach((ctrl) => {
      ctrl.patchValue({ isEditable: false });
    })
  }

  cancelEdit(element: any): void {
    if (element.value.isNewRow) {
      const index = this.dgsLinksArray.controls.indexOf(element);
      if (index !== -1) {
        this.dgsLinksArray.removeAt(index);
      }
    } else {
      element.patchValue({
        ...element.previousValue,
        isEditable: false,
      })
    }
 
    this.resetAllEditableFlags();
    this.updateDataSource();
  }

  deleteLink(linkId: number): void {
    this.maintananceLogSvc.deleteDGSLink(linkId).subscribe(() => {
      const index = this.dgsLinksArray.controls.findIndex((ctrl) => ctrl.value.linkId = linkId)
      if (index !== -1) {
        this.dgsLinksArray.removeAt(index)
      }
      this.dataSource.data = this.dgsLinksArray.controls;
      this.snackBar.open('Link deleted successfully', 'Close', { duration: 2000 });
    }
    )
  }

  getEventLog(eventId: number): void {
    this.maintananceLogSvc.getEventLog(eventId).subscribe((data: MaintenanceLog) => {
      this.maintenanceLog = data;

      // set values for initial form
      this.logForm.patchValue(
        {
          entryDate: this.maintenanceLog.entryDate == null ? '' : this.maintenanceLog.entryDate,
          dateIn: this.maintenanceLog.dateIn == null ? '' : this.maintenanceLog.dateIn,
          serialInstalled: this.maintenanceLog.serialInstalled == null ? '' : this.maintenanceLog.serialInstalled,
          serialRemoved: this.maintenanceLog.serialRemoved == null ? '' : this.maintenanceLog.serialRemoved,
          workOrderNumber: this.maintenanceLog.workOrderNumber == null ? '' : this.maintenanceLog.workOrderNumber,
          failureCause: this.maintenanceLog.failureCause == null ? '' : this.maintenanceLog.failureCause,
          failureReason: this.maintenanceLog.reason == null ? '' : this.maintenanceLog.reason,
          primarySecondarySeal: this.maintenanceLog.primarySecondarySealFail == null ? '' : this.maintenanceLog.primarySecondarySealFail,
          sealPlacement: this.maintenanceLog.sealPlacement == null ? '' : this.maintenanceLog.sealPlacement,
          comments: this.maintenanceLog.comments == null ? '' : this.maintenanceLog.comments
        });
    });
  }

  getBusinessUnits(): void {
    this.businessUnitSvc.getAllBusinessUnits()
      .subscribe({
        next:
          (data: BusinessUnit[]) => {
            this.businessUnits = [...data].sort((first, second) => (first.name > second.name ? 1 : -1));
            if (this.parameterBU !== null) {
              this.selectedBU = this.businessUnits.find(item => item.name === this.parameterBU);
              this.updateBusinessUnit();
            }
          },
        error: ((error: HttpErrorResponse) => {
          this.snackBar.open('Business units could not be loaded. Please try again later.', '', this.errorSnackBarConfig);
          console.log(error.error);
          throw error;
        }),

      });
  }

  getCompressors(): void {
    this.compressorSvc.getCompressors(this.selectedFacility.name)
      .subscribe({
        next: (data: Compressor[]) => {
          this.compressors = [...data].sort((first, second) => (first.compressorEquipmentId > second.compressorEquipmentId ? 1 : -1));
          if (this.parameterCompressorId !== null) {
            this.selectedCompressor = this.compressors.find(item => item.compressorEquipmentId === this.parameterCompressorId);
          }
        },
        error: ((error: HttpErrorResponse) => {
          this.snackBar.open('Equipment data could not be loaded. Please try again later.', '', this.errorSnackBarConfig);
          console.log(error.error);
        }),
      });
  }

  updateBusinessUnit(): void {
    this.logForm.get('compressor').disable();
    this.selectedFacility = undefined;
    this.selectedCompressor = undefined;
    this.updateFacility();
  }

  updateFacility(): void {

    if (this.selectedBU !== undefined) {
      this.logForm.get('facility').enable();

      this.facilities = [...this.selectedBU.facilities].sort((first, second) => (first.name > second.name ? 1 : -1));
      if (this.parameterFacility !== null) {
        this.selectedFacility = this.facilities.find(item => item.name === this.parameterFacility);
        this.updateEquipment();
      }
    }
  }

  updateEquipment(): void {
    this.selectedCompressor = undefined;

    if (this.selectedFacility !== undefined) {
      this.logForm.get('compressor').enable();
      this.getCompressors();
    }
  }

  selectChange(): void {
    this.selectedValue = this.selectedCompressor;
    this.selectedValue = this.selectedValue.compressorEquipmentId;
  }


  enableFailureReasons(): void {

    if (this.selectedreasonforfailure == 'Failure' || this.selectedreasonforfailure == 'Pending Failure') {
      this.isDisabled = false;
    }

    if (this.selectedreasonforfailure == 'PM' || this.selectedreasonforfailure == 'Paired Replacement') {
      this.isDisabled = true;
    }

  }

  hasError(controlName: string, errorName: string): boolean {
    return this.logForm.controls[controlName].hasError(errorName) &&
      this.logForm.controls[controlName].touched;
  }


  saveeventlog(): void {

    this.editedLog.id = this.parameterLogId;
    this.editedLog.compressorEquipmentId = this.selectedCompressor.compressorEquipmentId;
    this.editedLog.entryDate = this.maintenanceLog.entryDate;
    this.editedLog.dateIn = this.dateIn;
    this.editedLog.failureCause = this.selectedfailuretype;
    this.editedLog.sealPlacement = this.selectedsealplacement;
    this.editedLog.comments = this.comments;
    this.editedLog.serialInstalled = this.serialNumberInstalled;
    this.editedLog.serialRemoved = this.serialNumberRemoved;
    this.editedLog.workOrderNumber = this.workorder;
    this.editedLog.primarySecondarySealFail = this.selectedsealfailure;
    this.editedLog.reason = this.selectedreasonforfailure;

    if (this.logForm.valid) {
      this.maintananceLogSvc.putEventLog(this.editedLog).subscribe({
        next: (response) => this.snackBar.open('Event Log Updated!', '', this.successSnackBarConfig),
        error: (error) => {
          this.snackBar.open(`Failed to update Event Log. ${error.error.text}`, '', this.errorSnackBarConfig);
        }
      })
    }
  }
}
