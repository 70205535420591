import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SectionsService {

  GetSectionsUrl = '/Sections/getSections';

  constructor(private http: HttpClient) { }

  public getSections() {
    return this.http.get<any>(environment.apiEndpoint + this.GetSectionsUrl);
  }
}
