<div style="overflow-y: hidden !important;">

  <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
      Part Information
    </h1>
    <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">X</button>
  </div>

  <div mat-dialog-content>

    <div class="WarningMsgArea" *ngIf="partEntryInfo !==null && partEntryInfo?.set !== 0">
      <span class="warningmsg">
        <img alt="User Guide" src="/assets/images/warning.png" style="margin-top: -6px;" />
        <span style="font-weight: bold; padding-left: 10px;"> {{ partEntryInfo?.ucWarningMessages }} </span>
      </span>
    </div>

    <table class="BlackText" cellpadding="4" cellspacing="0">
      <tbody>
        <tr>
          <td>Organization:</td>
          <td>{{ partEntryInfo?.organization }}</td>
        </tr>
        <tr>
          <td>Unit Type:</td>
          <td>{{ partEntryInfo?.frameTypeDescription }}</td>
        </tr>
        <tr>
          <td>Component:</td>
          <td>{{ partEntryInfo?.component_Name }}</td>
        </tr>
        <tr>
          <td>{{ partEntryInfo?.kitDescription }} Number:</td>
          <td><span style="color:Red; font-weight:bold"></span>{{ partEntryInfo?.set_Number }}</td>
        </tr>
        <tr>
          <td>Number Of:</td>
          <td>{{ partEntryInfo?.numberOf }}</td>
        </tr>
        <tr>
          <td>DLN:</td>
          <td>{{ partEntryInfo?.dlnDescription }}</td>
        </tr>
        <tr>
          <td>Location:</td>
          <td>
            <span *ngIf="partEntryInfo?.status !== null">{{ partEntryInfo?.status }}</span>
            <span *ngIf="partEntryInfo?.status === null">Not In Use</span>
          </td>
        </tr>
      </tbody>
    </table>

    <input type="button" class="BlackText" value="Edit" style="width:100px; margin-top: 10px;"
    (click)="onEditClick(partEntryInfo)">

    <div class="row tabbed tabbed-content-control centered" style="margin-top: 10px;">
      <div class="filter-bar tab-filter-bar">
        <mat-tab-group [selectedIndex]="0" animationDuration="0ms" [disableRipple]='true' disablepagination="true">

          <mat-tab label="General">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <table class="BlackText" cellpadding="4" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>Part Life Classification:</td>
                      <td>{{ partEntryInfo?.partLifeClassificationDescription }}</td>
                    </tr>
                    <tr>
                      <td>Style:</td>
                      <td>{{ partEntryInfo?.style }}</td>
                    </tr>
                    <tr>
                      <td>Service Date:</td>
                      <td>{{ partEntryInfo?.service_Date | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td>Scrap Date:</td>
                      <td>{{ partEntryInfo?.scrap_Date | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td>Kit #:</td>
                      <td>{{ partEntryInfo?.kitNumber }}</td>
                    </tr>
                    <tr>
                      <td>Mfg Part #:</td>
                      <td>{{ partEntryInfo?.partNumber }}</td>
                    </tr>
                    <tr>
                      <td>Serial Number:</td>
                      <td>{{ partEntryInfo?.serialNumber }}</td>
                    </tr>
                    <tr>
                      <td>Stock ID:</td>
                      <td>{{ partEntryInfo?.stockId }}</td>
                    </tr>
                    <tr>
                      <td>Refurbished:</td>
                      <td>
                        <span class="aspNetDisabled">
                          <input type="checkbox" class="form-check-input" [ngModel]="partEntryInfo?.refurbished"
                            [checked]="partEntryInfo?.refurbished" style="height: 15px; width: 15px;" disabled="true">
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>2055 Compatible:</td>
                      <td>
                        <span class="aspNetDisabled">
                          <input type="checkbox" class="form-check-input" [ngModel]="partEntryInfo?._2055compatible"
                            [checked]="partEntryInfo?._2055compatible" style="height: 15px; width: 15px;"
                            disabled="true">
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Max # of Repairs:</td>
                      <td>{{ partEntryInfo?.maxNumberOfRepairs }}</td>
                    </tr>
                    <tr>
                      <td>Prior # of Repairs:</td>
                      <td>{{ partEntryInfo?.priorNumberOfRepairs }}</td>
                    </tr>
                    <tr>
                      <td>Starting Hours:</td>
                      <td>{{ partEntryInfo?.starting_Hours }}</td>
                    </tr>
                  </tbody>
                </table>
                <br>

                <table class="BlackText" cellpadding="4" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>Max Hours:</td>
                      <td align="right">{{ partEntryInfo?.max_Hours | number:'1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Current Hours:</td>
                      <td align="right">{{ partEntryInfo?.currentLifeTimeHours | number:'1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Remaining Hours:</td>
                      <td style="border-top:1px solid Black" align="right">
                        {{ partEntryInfo?.remainingHours | number:'1.2-2' }}<br>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Comments">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <table class="BlackText" cellpadding="4" cellspacing="0">
                  <tbody>
                    <tr>
                      <td valign="top">Comments:</td>
                      <td width="500">{{ partEntryInfo.comments }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="History">
            <ng-template matTabContent>
              <div>
                <table class="BlackText">
                  <tbody>
                    <tr>
                      <td>Legend:&nbsp;&nbsp;</td>
                      <td width="10" align="center">*</td>
                      <td>= Planned Outage</td>
                      <td width="10"><br></td>
                      <td width="10" class="PartHoursExceeded"><br></td>
                      <td style="padding-left: 5px;"> = Max Hours Exceed</td>
                    </tr>
                  </tbody>
                </table>

                <table mat-table matSort style="margin-top: 10px; border: 1px solid grey;"
                  [dataSource]="historyDataSource">
                  <!-- Company Column -->
                  <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Company </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      {{ row.company_Code }}
                    </td>
                    <td mat-cell *matCellDef="let row"> 1 </td>
                  </ng-container>

                  <!-- Unit Column -->
                  <ng-container matColumnDef="unit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Unit </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.unit }}
                    </td>
                  </ng-container>

                  <!-- Type Column -->
                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Type </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.outageType }}
                    </td>
                  </ng-container>

                  <!-- Date In Column -->
                  <ng-container matColumnDef="dateIn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Date In </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      <a href="javascript:void(0);" (click)="onDateInOutClick(row)">
                        {{ row.inDate | date: 'MM/dd/yyyy' }}
                      </a>
                    </td>
                  </ng-container>

                  <!-- Date Out Column -->
                  <ng-container matColumnDef="dateOut">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Date Out </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      <a href="javascript:void(0);" (click)="onDateInOutClick(row)">
                        {{ row.outDate | date: 'MM/dd/yyyy' }}
                      </a>
                    </td>
                  </ng-container>

                  <!-- Interval Column -->
                  <ng-container matColumnDef="interval">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Interval </th>
                    <td mat-cell *matCellDef="let row" class="text-right">
                      {{ row.intervalHours | number:'1.1-1' }}
                    </td>
                  </ng-container>

                  <!-- Lifetime Column -->
                  <ng-container matColumnDef="lifetime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Lifetime </th>
                    <td mat-cell *matCellDef="let row" class="text-right">
                      {{ row.lifeTimeHours | number:'1.1-1' }}
                    </td>
                  </ng-container>

                  <!-- Fired Starts Column -->
                  <ng-container matColumnDef="firedStarts">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Fired Starts </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      <span *ngIf="row.intervalFiredStarts === -1">N/A</span>
                      <span *ngIf="row.intervalFiredStarts !== -1">{{ row.intervalFiredStarts }}</span>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayHistoryColumns" style="color: white;"
                    class="header-color">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displayHistoryColumns;"></tr>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Serial Numbers ({{ serialNoCount }})">
            <ng-template matTabContent>
              <div style="margin-top: 10px; ">
                <table mat-table matSort [dataSource]="serialNumbersDataSource">
                  <!-- Serial Number Column -->
                  <ng-container matColumnDef="serialNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Serial Number </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      {{ row.serial_Number }}
                    </td>
                    <td mat-cell *matCellDef="let row"> 1 </td>
                  </ng-container>

                  <!-- Part Number Column -->
                  <ng-container matColumnDef="partNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Part Number </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      {{ row.partNumber }}
                    </td>
                  </ng-container>

                  <!-- Starting Hrs Column -->
                  <ng-container matColumnDef="startingHrs">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white; width: 10%;"
                      class="text-center"> Starting Hrs </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.starting_Hours | number:'1.2-2' }}
                    </td>
                  </ng-container>

                  <!-- Start Column -->
                  <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Start </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.start_Date | date: 'MM/dd/yyyy' }}
                    </td>
                  </ng-container>

                  <!-- End Column -->
                  <ng-container matColumnDef="end">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> End </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.end_Date | date: 'MM/dd/yyyy' }}
                    </td>
                  </ng-container>

                  <!-- Fired Starts Column -->
                  <ng-container matColumnDef="firedStarts">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Fired Starts </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      <span *ngIf=" row.firedStarts === -1"> N/A </span>
                      <span *ngIf=" row.firedStarts !== -1"> {{ row.firedStarts }} </span>
                    </td>
                  </ng-container>

                  <!-- Hours Column -->
                  <ng-container matColumnDef="hours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Hours </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.hours | number:'1.2-2' }}
                    </td>
                  </ng-container>

                  <!-- Lifetime Hours Column -->
                  <ng-container matColumnDef="lifetimeHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Lifetime Hours </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.lifeTimeHours | number:'1.2-2' }}
                    </td>
                  </ng-container>

                  <!-- Status Column -->
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Status </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.statusDescription }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displaySrNosColumns" style="color: white;" class="header-color">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displaySrNosColumns;"></tr>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Repairs ({{ repairsCount }})">
            <ng-template matTabContent>
              <div>
                <input type="submit" value="New Repair" class="BlackText">
                
                <table mat-table matSort [dataSource]="repairsDataSource" style="margin-top: 7px;">
                  <!-- Shipped Number Column -->
                  <ng-container matColumnDef="shipped">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Shipped </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.startDate | date: 'MM/dd/yyyy' }}
                    </td>
                    <td mat-cell *matCellDef="let row"> 1 </td>
                  </ng-container>

                  <!-- Returned Column -->
                  <ng-container matColumnDef="returned">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Returned </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      {{ row.endDate | date: 'MM/dd/yyyy' }}
                    </td>
                  </ng-container>

                  <!-- Plant Column -->
                  <ng-container matColumnDef="plant">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Plant </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      {{ row.companyCode }}
                    </td>
                  </ng-container>

                  <!-- Unit Column -->
                  <ng-container matColumnDef="unit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Unit </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      {{ row.unit }}
                    </td>
                  </ng-container>

                  <!-- Repair Facility Column -->
                  <ng-container matColumnDef="repairFacility">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Repair Facility </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      {{ row.repairFacility }}
                    </td>
                  </ng-container>

                  <!-- Internal Ref Column -->
                  <ng-container matColumnDef="internalRef">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Internal Ref # </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      {{ row.internalReferenceNumber }}
                    </td>
                  </ng-container>

                  <!-- External Ref Column -->
                  <ng-container matColumnDef="externalRef">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> External Ref # </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                      {{ row.externalReferenceNumber }}
                    </td>
                  </ng-container>

                  <!-- View Column -->
                  <ng-container matColumnDef="view">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      <a href="javascript:void(0);" (click)="onViewClick(row)">View</a>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayRepaiesColumns" style="color: white;"
                    class="header-color">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displayRepaiesColumns;"
                    [ngStyle]="{'background-color': row.rowColor}"></tr>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Links ({{ links.length}})">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <div *ngIf="links && links.length > 0; else noLinks">
                  <table style="border: black;" cellpadding="4" cellspacing="0">
                    <tbody>
                      <tr class="Header outage-info" style="text-align: center;">
                        <th style="color: white; background-color: #009dd0;">Link</th>
                      </tr>
                      <tr style="text-align: center;">
                        <td>
                          <div *ngFor="let linkItem of links">
                            <a [href]="linkItem.link" target="_blank">
                              {{ linkItem.description }}
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ng-template #noLinks>
                  <span class="BlackText">No links found</span>
                </ng-template>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>